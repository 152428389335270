import React from 'react';
import { graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import Helmet from 'react-helmet';
import GradeLetters, { GradeLettersProps } from '@mechninja/grade-letters';
import Layout from '../components/Layout';
import styles from './report.module.scss';
import Footer from '../components/Footer';

export const pageQuery = graphql`
  query($name: String!) {
    report: file(name: {eq: $name}) {
      name
      childMarkdownRemark {
        frontmatter {
          title
          date
          author
          grade
          period
        }
        html
      }
    }
  }
`;

interface ReportQuery {
  data: {
    report: {
      name: string;
      childMarkdownRemark: {
        frontmatter: {
          title: string;
          date: string;
          author: string;
          grade: string;
          period: number;
        };
        html: string;
      };
    };
  };
}

type SplitGradeLetter = Pick<GradeLettersProps, 'gradeLetter' | 'gradeModifier'>;

const splitGradeLetter = (grade: string): SplitGradeLetter => {
  return {
    gradeLetter: grade.substr(0, 1) as GradeLettersProps['gradeLetter'],
    gradeModifier: grade.substr(1, 1) as GradeLettersProps['gradeModifier'],
  };
};

const checksum = (s: string): number => {
  let chk = 0x12345678;
  const len = s.length;
  for (let i = 0; i < len; i++) {
    chk += (s.charCodeAt(i) * (i + 1));
  }

  return (chk & 0xffffffff);
};

const Report = ({ data: { report: { name, childMarkdownRemark } } }: ReportQuery) => (
  <Layout>
    <Helmet>
      <title>
        {`${childMarkdownRemark.frontmatter.title} | New Freedom City`}
      </title>
    </Helmet>
    <div className={styles.report}>
      <GradeLetters
        className={styles.grade}
        hash={checksum(name)}
        fill="#ff0000"
        {...splitGradeLetter(childMarkdownRemark.frontmatter.grade)}
      />
      <div className={styles.reportMeta}>
        <p>{childMarkdownRemark.frontmatter.author}</p>
        <p>{`Period ${childMarkdownRemark.frontmatter.period}`}</p>
        <p>{childMarkdownRemark.frontmatter.date}</p>
      </div>
      <h1>{childMarkdownRemark.frontmatter.title}</h1>

      <hr />
      {ReactHtmlParser(childMarkdownRemark.html)}
    </div>
    <Footer />
  </Layout>
);

export default Report;
